<template>
  <div>
    <!-- TOP BTNS -->
    <md-button class="md-raised md-icon-button mg-left-0" @click="reloadData">
      <font-awesome-icon icon="redo" />
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      @click="onSearchClick"
    >
      <font-awesome-icon icon="search" />
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'PackingProfileCreate' }"
    >
      <font-awesome-icon icon="plus" />
    </md-button>

    <packing-profile-search
      ref="packing-profile-search"
      v-show="searchVisible"
    />

    <md-progress-bar v-if="loading" md-mode="indeterminate" />
    <packing-profile-content
      v-else
      ref="content"
      class="content"
      :style="contentHeigth"
      :heigth="height"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PackingProfileContent from "../../components/packingProfile/PackingProfileContent.vue";
import {
  PACKING_PROFILE_LOAD,
  PACKING_PROFILE_RELOAD,
  PACKING_PROFILE_UNLOAD,
  TOOLBAR_TITLE,
} from "../../store/actions.types";
import PackingProfileSearch from "../../components/packingProfile/PackingProfileSearch.vue";

export default {
  name: "ProfilesAnalysis",
  components: {
    PackingProfileContent,
    PackingProfileSearch,
  },

  data() {
    return {
      loading: true,
      contentHeigth: "",
      height: 100,
      searchVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      packingProfiles: "getPackingProfiles",
    }),
  },

  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "packing_profile");
    this.loadData();
    window.addEventListener("resize", this.calculateHeight);
  },

  beforeDestroy() {
    this.$store.dispatch(PACKING_PROFILE_UNLOAD);
    window.removeEventListener("resize", this.calculateHeight);
  },

  methods: {
    async loadData() {
      await this.$store.dispatch(PACKING_PROFILE_LOAD);
      this.loading = false;
      this.$nextTick(() => {
        this.calculateHeight();
      });
    },

    reloadData() {
      this.$store.dispatch(PACKING_PROFILE_RELOAD);
    },

    calculateHeight() {
      let min = this.$refs.content.$el.getBoundingClientRect().top;
      let max = window.innerHeight;
      let size = max - min - 17;
      this.height = size;
      this.contentHeigth = `height: ${size}px;`;
    },

    onSearchClick() {
      this.searchVisible = !this.searchVisible;
      this.$nextTick(() => {
        this.calculateHeight();
        if (this.searchVisible) {
          // Focus al buscador
          this.$refs["packing-profile-search"].$refs[
            "packaging-search"
          ].$el.focus();
        }
      });
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 10px;
}
</style>