<template>
  <div>
    <md-list>
      <md-list-item
        v-for="profile in packingProfiles.content"
        @click="clicked(profile)"
        :key="profile.id"
        :class="{ selected: profile.id === selected.id }"
      >
        <div class="md-list-item-text">
          <span>{{ profile.name }}</span>
          <span>{{ formatDateTime(profile.createdDate) }} </span>
        </div>
      </md-list-item>

      <md-list-item
        v-if="!this.packingProfiles.last"
        class="load-more"
        v-observe-visibility="visibilityChange"
        @click="loadMore"
      >
        {{ $t("load_more") }}...
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateMixin } from "../../common/mixins/DateMixin";
import {
  PACKING_PROFILE_LOAD_MORE,
  PACKING_PROFILE_SELECT_DETAILS,
} from "../../store/actions.types";

export default {
  name: "PackingProfileList",
  mixins: [DateMixin],
  computed: {
    ...mapGetters({
      packingProfiles: "getPackingProfiles",
      selected: "getSelectedProfile",
    }),
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    clicked(profile) {
      this.$store.dispatch(PACKING_PROFILE_SELECT_DETAILS, profile);
      this.$emit("selected");
    },
    visibilityChange(isVisible) {
      if (isVisible) {
        this.loadMore();
      }
    },
    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(PACKING_PROFILE_LOAD_MORE).finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.selected {
  background: #ebebeb;
}
.load-more .md-list-item-container >>> .md-list-item-content {
  justify-content: center;
}
</style>