<template>
  <div v-if="packingProfiles && packingProfiles.totalElements > 0">
    <div class="md-layout md-alignment-top-space-between">
      <!-- LIST -->
      <packing-profile-content-list
        class="
          md-layout-item md-size-20 md-medium-size-30 md-small-size-100
          content-info
        "
        :class="{ 'md-small-hide': hideList }"
        :style="`height: ${heigth - 15}px`"
        @selected="selected"
      />
      <!-- DETAILS -->
      <packing-profile-content-info
        class="
          md-layout-item md-size-75 md-medium-size-65 md-small-size-100
          content-info
        "
        :class="{ 'md-small-hide': !hideList }"
        :style="`height: ${heigth - 15}px`"
        @back="back"
      />
    </div>
  </div>
  <div v-else>
    <md-empty-state
      md-rounded
      md-icon="add"
      :md-label="$t('no_profiles_created')"
    >
      <md-button
        class="md-raised md-primary"
        :to="{ name: 'PackingProfileCreate' }"
      >
        {{ $t("create") }}
      </md-button>
    </md-empty-state>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PackingProfileContentInfo from "./PackingProfileContentInfo.vue";
import PackingProfileContentList from "./PackingProfileContentList.vue";
export default {
  name: "PackingProfileContent",
  components: {
    PackingProfileContentList,
    PackingProfileContentInfo,
  },
  props: {
    heigth: Number,
  },
  computed: {
    ...mapGetters({
      packingProfiles: "getPackingProfiles",
    }),
  },
  data() {
    return {
      hideList: false,
    };
  },

  methods: {
    selected() {
      this.hideList = true;
    },
    back() {
      this.hideList = false;
    },
  },
};
</script>

<style scoped>
.content-info {
  height: 400px;
  overflow-y: auto;
}
</style>