<template>
  <div v-if="profile.id" class="c">
    <md-button
      class="md-raised md-icon-button mg-left-0 btn-back"
      @click="back"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>

    <packing-profile-form
      :profile="profile"
      :disabled="submiting"
      @onSubmit="onsubmit"
    />

    <md-button
      class="md-accent md-raised md-icon-button mg-left-0 remove"
      @click="remove"
    >
      <font-awesome-icon icon="trash" />
    </md-button>
  </div>
  <div v-else>
    <!-- SI NO HAY NINGUNO SELECCIONADO -->
    <md-empty-state
      md-rounded
      md-icon="reorder"
      :md-label="$t('first_select_a_profile')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PackingProfile } from "../../common/api/api.service";
import {
  ALERT,
  CONFIRM_SHOW,
  PACKING_PROFILE_RELOAD,
  PACKING_PROFILE_SELECT_DETAILS,
} from "../../store/actions.types";
import PackingProfileForm from "./PackingProfileForm.vue";
export default {
  name: "PackingProfileContentInfo",
  components: {
    PackingProfileForm,
  },

  computed: {
    ...mapGetters({
      profile: "getSelectedProfile",
    }),
  },

  data() {
    return {
      isEditing: false,
      submiting: false,
    };
  },

  methods: {
    onsubmit(form) {
      this.submiting = true;
      PackingProfile.put(form)
        .then(() => {
          this.isEditing = false;
          this.$toast.success(this.$t("updated!"));
          this.$store.dispatch(PACKING_PROFILE_RELOAD);
          this.$store.dispatch(PACKING_PROFILE_SELECT_DETAILS, {});
          this.$emit("back");
        })
        .catch(() => {
          this.$store.dispatch(ALERT, { message: "error" });
        })
        .finally(() => {
          this.submiting = false;
        });
    },

    remove() {
      this.$store.dispatch(CONFIRM_SHOW, {
        title: "remove?",
        warning: true,
        cancel: "cancel",
        confirm: "remove",
        onConfirm: () => {
          PackingProfile.delete(this.profile.id).then(() => {
            this.$emit("back");
            this.$store.dispatch(PACKING_PROFILE_SELECT_DETAILS, {});
            this.$store.dispatch(PACKING_PROFILE_RELOAD);
          });
        },
      });
    },

    back() {
      this.$emit("back");
    },
  },
};
</script>

<style scoped>
.c {
  padding-right: 10px;
}
@media (min-width: 959px) {
  .btn-back {
    display: none;
  }
}
.btn-back {
  margin-bottom: 10px;
}
.remove {
  margin-top: 10px;
}
</style>